import "@styles/font.css";
import "@styles/typography.css";
import "@styles/base.css";
import "@styles/layout.css";
import "@styles/colors.css";
import "@styles/common.css";
import "@styles/buttons.css";
import "@styles/locomotive-scroll.css";

import Intro from "@pages/Intro";
import Maison from "@pages/Maison";
import Rooms from "@pages/Rooms";
import Bazar from "@pages/Bazar";
import LunchAperitif from "@pages/Lunch_Aperitif";
import Contacts from "@pages/Contacts";
import Story from "@pages/Story";
import Maratea from "@pages/Maratea";

import Header from "@blocks/Header";
import Nav from "@blocks/Nav";
import Loader from "@blocks/Loader";
import PageTransitioner from "@blocks/PageTransitioner";
import Hamburger from "@blocks/Hamburger";
import PagesCards from "@blocks/PagesCards";
import BazarIntro from "@blocks/BazarIntro";
import LunchAperitifIntro from "@blocks/LunchAperitifIntro";
import MaisonIntro from "@blocks/MaisonIntro";
import MaisonImages from "@blocks/MaisonImages";
import RoomsIntro from "@blocks/RoomsIntro";
import RoomsImages from "@blocks/RoomsImages";
import BazarImages from "@blocks/BazarImages";
import LunchAperitifImages from "@blocks/LunchAperitifImages";
import ContactsMap from "@blocks/ContactsMap";
import ContactsList from "@blocks/ContactsList";
import StoryIntro from "@blocks/StoryIntro";
import StoryImages from "@blocks/StoryImages";
import MarateaIntro from "@blocks/MarateaIntro";
import MarateaImages from "@blocks/MarateaImages";

import LanguageSelector from "@components/LanguageSelector";
import Columns from "@components/Columns";
import PageCard from "@components/PageCard";
import PageContent from "@components/PageContent";
import HorizontalPageImage from "@components/HorizontalPageImage";
import Anchor from "@components/Anchor";
import AnimatedWord from "@components/AnimatedWord";
import AnimatedText from "@components/AnimatedText";
import TextReveal from "@components/TextReveal";
import ImageNode from "@components/ImageNode";

import Raf from "@utils/Raf";
import Observer from "@utils/Observer";
import Scroll from "@utils/Scroll";
import vh from "@utils/vh";
import { getRandomInt, getRandomIntExclusively } from "@utils/random";

import Router from "@router/index.js";
import Translator from "@translator/index.js";

const MODULES = [
  Intro,
  Maison,
  Rooms,
  Bazar,
  LunchAperitif,
  Contacts,
  Story,
  Maratea,
  PageTransitioner,
  Header,
  Nav,
  Hamburger,
  PagesCards,
  BazarIntro,
  LunchAperitifIntro,
  StoryImages,
  MaisonIntro,
  MaisonImages,
  RoomsIntro,
  RoomsImages,
  BazarImages,
  LunchAperitifImages,
  ContactsMap,
  ContactsList,
  MarateaIntro,
  MarateaImages,
  StoryIntro,
  LanguageSelector,
  Columns,
  Anchor,
  AnimatedWord,
  TextReveal,
  AnimatedText,
  ImageNode,
];

class App {
  lastIndex = 0;

  constructor() {
    window.addEventListener("DOMContentLoaded", this.prepareToInitApp);
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);
  }

  prepareToInitApp = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js").then(
        function (registration) {
          // Registration was successful
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        },
        function (err) {
          // registration failed :(
          console.log("ServiceWorker registration failed: ", err);
        }
      );
    }

    this.router = new Router();
    this.observer = new Observer();
    this.raf = new Raf();
    if (document.querySelector("[data-scroll-container]")) {
      this.scroll = new Scroll();
    }

    this.initApp();
  };

  initApp = () => {
    document.documentElement.style.setProperty("--vh", `${vh()}px`);
    document.documentElement.style.setProperty("--dvh", `${vh()}px`);

    const lastImagePlaceholderIndex = window.localStorage.getItem(
      "imagePlaceholderIndex"
    );
    const imagePlaceholderIndex = lastImagePlaceholderIndex
      ? getRandomIntExclusively(4, parseInt(lastImagePlaceholderIndex, 10))
      : getRandomInt(4);
    window.localStorage.setItem("imagePlaceholderIndex", imagePlaceholderIndex);
    document.body.classList.add(`image-placeholder--${imagePlaceholderIndex}`);

    this.lastIndex = 0;
    this.instances = MODULES.flatMap((Module) => {
      if ("selector" in Module) {
        const blocks = [...document.querySelectorAll(Module.selector)];

        return blocks.map((block) => {
          block.setAttribute("data-instance-index", this.lastIndex);
          this.lastIndex += 1;
          return new Module(block);
        });
      }
      return null;
    }).filter(Boolean);

    this.translator = new Translator();

    for (const instance of this.instances) {
      if (instance.onReady) {
        instance.onReady();
      }
    }

    const loader = new Loader();

    if (loader.subscribe) {
      loader.subscribe(() => {
        for (const instance of this.instances) {
          if (instance.onComplete) {
            instance.onComplete();
          }
        }

        if (this.scroll) {
          this.onScrollReady();
          this.scroll.update();
        }
      });
    }
  };

  initClones() {
    this.instances = this.instances.concat(
      MODULES.flatMap((module) => {
        if (!module.hasOwnProperty("selector")) {
          return;
        }

        const blocks = [...document.querySelectorAll(module.selector)];
        return blocks
          .map((block) => {
            const blockInstanceIndex = block.getAttribute(
              "data-instance-index"
            );
            if (blockInstanceIndex && blockInstanceIndex !== "") {
              return null;
            }

            block.setAttribute("data-instance-index", this.lastIndex);
            this.lastIndex++;
            return new module(block);
          })
          .filter((v) => !!v);
      })
    );

    this.instances.forEach((instance) => {
      if (instance.onReady && !instance.mounted) {
        instance.onReady();
      }
    });
  }

  onResize = (e) => {
    document.body.classList.add("resizing");

    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      document.body.classList.remove("resizing");
    }, 400);

    if (!this.instances) {
      return;
    }

    document.documentElement.style.setProperty("--dvh", `${vh()}px`);

    for (const instance of this.instances) {
      if (instance.onResize) {
        // instance.onResize(e, { changedView });
        instance.onResize(e);
      }
    }
  };

  onScroll = (e) => {
    if (!this.instances) {
      return;
    }

    for (const instance of this.instances) {
      if (instance.onScroll) {
        instance.onScroll(e);
      }
    }
  };

  onPageChangeComplete = () => {
    for (const instance of this.instances) {
      if (instance.onPageChangeComplete) {
        instance.onPageChangeComplete();
      }
    }
  };

  onScrollReady = () => {
    for (const instance of this.instances) {
      if (instance.onScrollReady) {
        instance.onScrollReady();
      }
    }
  };

  onTranslationComplete = () => {
    this.router.onTranslationComplete();
  };

  getOrInitScroll = () => {
    if (document.querySelector("[data-scroll-container]") && !this.scroll) {
      this.scroll = new Scroll();
    }

    return window.App.scroll;
  };

  translate = () => {
    this.translator.subscribeOnTranslationComplete(() => {
      this.onTranslationComplete();
    });
    this.translator.translate();
  };
}

window.App = new App();

export const getRaf = () => {
  return window.App.raf;
};

export const getScroll = () => {
  return window.App.scroll;
};

export const getOrInitScroll = () => {
  return window.App.getOrInitScroll();
};

export const getObserver = () => {
  return window.App.observer;
};

export const getTranslator = () => {
  return window.App.translator;
};

export const getInstance = (el) => {
  const instanceIndex = el.getAttribute("data-instance-index");

  if (!instanceIndex) {
    return null;
  }

  return window.App.instances[parseInt(instanceIndex, 10)];
};
