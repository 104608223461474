import "./style.css";
import layout from "./layout.html";

export default class LunchAperitifImages {
  static selector = ".lunch-aperitif__images";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
  }

  onReady = () => {};

  onComplete = () => {};

  onResize = () => {};
}
