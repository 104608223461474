import LocomotiveScroll from "locomotive-scroll";

export default class Scroll {
  constructor() {
    this.scrollItems = [];
    this.directionChangeCallbacks = [];
    this.positionChangeCallbacks = [];
    this.scrollItemsInView = [];
    this.scrollLimitCallbacks = [];
    this.scrollContainer = document.querySelector("[data-scroll-container]");
    this.direction = this.scrollContainer.dataset.direction
      ? this.scrollContainer.dataset.direction
      : "vertical";

    this.scroll = new LocomotiveScroll({
      el: this.scrollContainer,
      smooth: true,
      direction: this.direction,
      scrollbarClass: "minervetta-scrollbar",
      tablet: {
        smooth: true,
        direction: this.direction,
        gestureDirection: "both",
      },
      smartphone: {
        smooth: true,
        direction: this.direction,
        gestureDirection: "both",
      },
    });

    this.scroll.on("scroll", this.onScroll);
  }

  scrollLimitChanged = (limit) => {
    this.limit = limit;
    this.scrollLimitCallbacks.forEach((callback) => {
      callback(limit);
    });
  };

  onScroll = (args) => {
    const progress = Math.round((args.scroll.x / args.limit.x) * 100);
    switch (progress) {
      case 0:
        if (this.limit !== "left") {
          this.scrollLimitChanged("left");
        }
        break;

      case 100:
        if (this.limit !== "right") {
          this.scrollLimitChanged("right");
        }
        break;

      default:
        if (this.limit !== null) {
          this.scrollLimitChanged(null);
        }
        break;
    }

    this.positionChangeCallbacks.forEach((callback) => {
      callback(args.scroll);
    });

    if (
      args.direction &&
      (args.direction !== this.direction || !this.direction)
    ) {
      this.direction = args.direction;

      this.directionChangeCallbacks.forEach((callback) => {
        callback(this.direction);
      });
    }

    this.scrollItems.forEach((item) => {
      if (typeof args.currentElements[item.scrollID] === "object") {
        item.callback(args.currentElements[item.scrollID]);
      }
    });
  };

  register = (scrollID, callback) => {
    this.scrollItems.push({
      scrollID: scrollID,
      callback: callback,
    });
  };

  registerOnInViewTrigger = (scrollID, callback) => {
    this.scrollItemsInView.push({
      scrollID: scrollID,
      callback: callback,
    });
  };

  registerOnScrollPositionChange = (callback) => {
    this.positionChangeCallbacks.push(callback);
  };

  registerOnScrollDirectionChange = (callback) => {
    this.scrollLimitCallbacks.push(callback);
  };

  registerOnScrollLimitChange = (callback) => {
    this.scrollLimitCallbacks.push(callback);
  };

  scrollTo = (section, options = {}) => {
    this.scroll.scrollTo(section, options);
  };

  stop = () => {
    this.scroll.stop();
  };

  start = () => {
    this.scroll.start();
  };

  update = () => {
    this.limit = null;
    this.scroll.update();
  };
}
