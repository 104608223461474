import "./style.css";

export default class TextReveal {
  static selector = ".text-reveal";

  constructor(block) {
    this.block = block;

    this.entranceDelegated = block.dataset.entranceDelegated;
    this.revealClasses = block.dataset.revealClasses
      ? block.dataset.revealClasses.split(" ")
      : null;
    this.transitionDuration = 1000;
  }

  setupWord = () => {
    let wordWrapper = document.createElement("div");
    wordWrapper.classList.add("text-reveal__text");

    this.word.split("").forEach((letter, index) => {
      let letterWrapper = document.createElement("div");
      letterWrapper.classList.add("text-reveal__text__letter-wrapper");

      let span = document.createElement("span");
      span.classList.add("text-reveal__text__letter-wrapper__letter");
      span.style.transitionDelay = `${0.012 * index}s`;
      span.textContent = letter;

      letterWrapper.appendChild(span);
      wordWrapper.appendChild(letterWrapper);
    });

    return wordWrapper;
  };

  setupWords = () => {
    this.block.textContent = "";

    this.textNode = this.setupWord();
    this.textRevealNode = this.setupWord();
    this.textRevealNode.classList.add("text-reveal__text--reveal");
    if (this.revealClasses) {
      this.revealClasses.forEach((className) => {
        this.textRevealNode.classList.add(className);
      });
    }

    this.block.appendChild(this.textNode);
    this.block.appendChild(this.textRevealNode);
  };

  hide = () => {
    this.block.classList.remove("text-reveal--visible");
  };

  show = () => {
    if (this.observer) {
      this.observer.unregister(this.block);
    }

    this.block.classList.add("text-reveal--visible");
  };

  onResize = () => {
    //this.setupRows();
  };

  onReady = () => {
    this.mounted = true;
    this.word = this.block.textContent.trim();

    this.setupWords();

    /*if (!this.entranceDelegated) {
      this.observer = getObserver();
      this.observer.register(
        this.block.dataset.instanceIndex,
        this.show,
        this.block
      );
    }*/
  };

  onTranslationComplete = () => {
    this.word = this.block.textContent.trim();
    this.setupWords();
  };
}
