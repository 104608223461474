import translations from "./translations";
import { getInstance } from "@app";

export default class Translator {
  constructor() {
    this.callbacks = [];
    this.translate();
  }

  translate = () => {
    this.currentLanguage = window.location.hash.substring(1);

    if (this.currentLanguage.length > 0 && this.currentLanguage) {
      const textsToTranslate = document.querySelectorAll(
        "[data-translation-id]"
      );

      textsToTranslate.forEach((text) => {
        const translationID = parseInt(text.dataset.translationId, 10);
        text.textContent = translations[translationID][this.currentLanguage];

        const textInstance = getInstance(text);
        if (
          textInstance &&
          textInstance.onTranslationComplete &&
          textInstance.mounted
        ) {
          textInstance.onTranslationComplete();
        }
      });
    }

    this.callbacks.forEach((callback) => {
      callback();
    });
    this.callbacks = [];
  };

  subscribeOnTranslationComplete = (callback) => {
    this.callbacks.push(callback);
  };
}
