import "./style.css";
import { getInstance } from "@app";
import layout from "./layout.html";

export default class PagesCards {
  static selector = ".pages-cards";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
  }

  onReady = () => {};

  onComplete = () => {};

  onResize = () => {};
}
