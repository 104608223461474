import "./style.css";
import layout from "./layout.html";

export default class BazarImages {
  static selector = ".bazar__images";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
  }

  onReady = () => {};

  onComplete = () => {};

  onResize = () => {};
}
