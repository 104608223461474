import "./style.css";
import { getInstance } from "@app";

export default class LanguageSelector {
  static selector = ".language-selector";

  constructor(block) {
    this.block = block;
    this.selectorItems = block.querySelectorAll(
      ".language-selector__item a .text-reveal"
    );
    this.selectorItemsInstances = [];

    this.opened = false;
    this.duration = 800;
  }

  updateCurrentLanguageLink = () => {
    const hash = window.location.hash.substring(1);

    if (hash.length < 1 || !hash) {
      return;
    }

    this.selectorItems &&
      this.selectorItems.forEach((node) => {
        node.classList.toggle(
          "text-reveal--revealed",
          node.parentNode.dataset.lang === hash
        );
      });
  };

  hide = (transition = true) => {
    if (!transition) {
      this.selectorItemsInstances.forEach((instance) => {
        if (instance.hide) {
          instance.hide();
        }
      });
      this.block.style.transition = "none";
      this.block.parentNode.classList.remove("language-selector--visible");
      window.setTimeout(() => {
        this.block.style.transition = null;
      }, 100);
    } else {
      this.selectorItemsInstances.forEach((instance) => {
        if (instance.hide) {
          instance.hide();
        }
      });

      setTimeout(() => {
        this.block.parentNode.classList.remove("language-selector--visible");
      }, this.duration / 4);
    }
  };

  show = () => {
    this.block.parentNode.classList.add("language-selector--visible");

    this.selectorItemsInstances.forEach((instance, index) => {
      setTimeout(() => {
        if (instance.show) {
          instance.show();
        }
      }, this.duration / 4 + index * 24);
    });
  };

  changeState = (transition = true) => {
    if (!this.opened) {
      this.show();
    } else {
      this.hide(transition);
    }

    this.opened = !this.opened;
  };

  toggleButtonClicked = () => {
    this.changeState();
  };

  onResize = () => {};

  onReady = () => {};

  onComplete = () => {
    this.toggleButton = document.querySelector(".header__languages-toggle");

    if (this.toggleButton) {
      this.toggleButton.addEventListener("click", this.toggleButtonClicked);
    }

    if (this.selectorItems) {
      this.selectorItems.forEach((node) => {
        const instance = getInstance(node);

        if (instance) {
          this.selectorItemsInstances.push(instance);
        }
      });
    }

    this.updateCurrentLanguageLink();
  };
}
