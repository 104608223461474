import "./style.css";

export default class ImageNode {
  static selector = ".image";

  constructor(block) {
    this.block = block;
    this.image = block.querySelector(".image__image");

    if (this.image.nodeName === "IMG") {
      // that's an image
      this.src = this.image.getAttribute("src");
    } else {
      this.src = this.image.style.backgroundImage.replace(
        /url\((['"])?(.*?)\1\)/gi,
        "$2"
      );
    }

    let image = new Image();
    image.addEventListener("load", () => {
      this.block.classList.add("image--loaded");
    });
    image.src = this.src;
  }
}
