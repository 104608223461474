import "./style.css";
import { getObserver } from "@app";

export default class AnimatedWord {
  static selector = ".animated-word";

  constructor(block) {
    this.block = block;

    this.entranceDelegated = block.dataset.entranceDelegated;
    this.transitionDuration = 1000;
    this.word = block.textContent.trim();
  }

  setupWord = () => {
    this.block.textContent = "";

    this.word.split("").forEach((letter, index) => {
      let wrapper = document.createElement("div");
      wrapper.classList.add("animated-word__letter-wrapper");

      let span = document.createElement("span");
      span.classList.add("animated-word__letter");
      //span.style.transitionDelay = `${0.008 * index}s`;
      span.textContent = letter;

      wrapper.appendChild(span);
      this.block.appendChild(wrapper);

      this.letters.push(span);
    });
  };

  hide = () => {
    this.block.classList.remove("animated-word--visible");
  };

  show = () => {
    if (this.observer) {
      this.observer.unregister(this.block);
    }

    this.block.classList.add("animated-word--visible");
  };

  onResize = () => {
    //this.setupRows();
  };

  onReady = () => {
    this.raf = [];
    this.animationStartTimestamp = [];
    this.letters = [];

    this.setupWord();

    if (!this.entranceDelegated) {
      this.observer = getObserver();
      this.observer.register(
        this.block.dataset.instanceIndex,
        this.show,
        this.block
      );
    }
  };
}
