// returns a random number between 0 (inclusive) and 1 (exclusive)
const getRandom = () => {
  return Math.random();
};

// returns a random number between min (inclusive) and max (exclusive)
const getRandomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min;
};

// returns a random integer between 0 (inclusive) and max (exclusive)
const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

// returns a random integer between 0 (inclusive) and max (exclusive) excluding a specified value
const getRandomIntExclusively = (max, exclude) => {
  var num = Math.floor(Math.random() * max);
  return num === exclude ? getRandomIntExclusively(max, exclude) : num;
};

// returns a random integer between min (inclusive) and max (exclusive)
const getRandomIntFromRange = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

// returns a random integer between min (inclusive) and max (exclusive) excluding a specified value
const getRandomIntFromRangeExclusively = (min, max, exclude) => {
  var num = Math.floor(Math.random() * (max - min + 1)) + min;
  return num === exclude
    ? getRandomIntFromRangeExclusively(min, max, exclude)
    : num;
};

export {
  getRandom,
  getRandomInt,
  getRandomIntFromRange,
  getRandomIntFromRangeExclusively,
  getRandomArbitrary,
  getRandomIntExclusively,
};
