const translations = {
  0: {
    it: "it",
    en: "en",
    de: "de",
    fr: "fr",
    da: "da",
  },

  1: {
    it: "La Maison",
    en: "The Maison",
    de: "Das Haus",
    fr: "La Maison",
    da: "Huset",
  },

  2: {
    it: "Le Camere",
    en: "The Rooms",
    de: "Die Zimmer",
    fr: "Les Pièces",
    da: "Værelser",
  },

  3: {
    it: "Il Bazar",
    en: "The Bazaar",
    de: "Der Basar",
    fr: "Le Bazar",
    da: "Bazaren",
  },

  4: {
    it: "Per Ispirare",
    en: "To Inspire",
    de: "Um ZuInspirieren",
    fr: "Pour Inspirer",
    da: "At Inspirere",
  },

  5: {
    it: "Le Storie",
    en: "The Stories",
    de: "Die Geschichten",
    fr: "Les Histoires",
    da: "Historierne",
  },

  6: {
    it: "Prenota",
    en: "Book",
    de: "Buch",
    fr: "Livre",
    da: "Bestil",
  },

  7: {
    it: "Esplora",
    en: "Explore",
    de: "Entdecken Sie",
    fr: "Explorez",
    da: "Udforsk",
  },

  8: {
    it: "La Minervetta",
    en: "The Minervetta",
    de: "Die Minervetta",
    fr: "La Minervetta",
    da: "Minervettaen",
  },

  9: {
    it: "La",
    en: "The",
    de: "Das",
    fr: "La",
    da: "Huset",
  },

  10: {
    it: "Maison",
    en: "Maison",
    de: "Haus",
    fr: "Maison",
    da: "",
  },

  11: {
    it: "Le",
    en: "The",
    de: "Die",
    fr: "Les",
    da: "Værelser",
  },

  12: {
    it: "Camere",
    en: "Rooms",
    de: "Zimmer",
    fr: "Pièces",
    da: "",
  },

  13: {
    it: "Il",
    en: "The",
    de: "Der",
    fr: "Le",
    da: "Bazaren",
  },

  14: {
    it: "Bazar",
    en: "Bazaar",
    de: "Basar",
    fr: "Bazar",
    da: "",
  },

  15: {
    it: "Per",
    en: "To",
    de: "Um",
    fr: "Pour",
    da: "At",
  },

  16: {
    it: "Ispirare",
    en: "Inspire",
    de: "Zu Inspirieren",
    fr: "Inspirer",
    da: "Inspirere",
  },

  16: {
    it: "Le",
    en: "The",
    de: "Die",
    fr: "Les",
    da: "Historierne",
  },

  17: {
    it: "Storie",
    en: "Stories",
    de: "Geschichten",
    fr: "Histoires",
    da: "",
  },

  18: {
    it: "Visita Lo Shop",
    en: "Visit the Shop",
    de: "Besuch des Verkaufsladens",
    fr: "Visit Shop",
    da: "Besøg butikken",
  },

  19: {
    it: "Indirizzo",
    en: "Address",
    de: "Adresse",
    fr: "Adresse",
    da: "Adresse",
  },

  20: {
    it: "Contatti",
    en: "Contacts",
    de: "Kontakte",
    fr: "Contacts",
    da: "Kontaktpersoner",
  },
};
export default translations;
