import "./style.css";

export default class Maison {
  static selector = ".maison";

  constructor(block) {
    this.block = block;
    this.mounted = false;
  }

  onReady = () => {
    this.mounted = true;
  };

  onPageChangeComplete = () => {
    this.onComplete();
  };

  onComplete = () => {};
}
