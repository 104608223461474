import "./style.css";
import layout from "./layout.html";

import { getInstance } from "@app";

export default class Nav {
  static selector = ".nav";

  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;

    this.animatedTexts = block.querySelectorAll(".text-reveal");
    this.navBands = block.querySelectorAll(".nav__bands__band");
    this.animatedTextsInstances = [];
    this.opened = false;
    this.duration = 1000;
  }

  hide = (transition = true) => {
    if (!transition) {
      this.block.style.transition = "none";
      document.body.classList.remove("nav-visible");
      window.setTimeout(() => {
        this.block.style.transition = null;
      }, 100);
    } else {
      this.animatedTextsInstances.forEach((instance) => {
        if (instance.hide) {
          instance.hide();
        }
      });

      this.block.style.pointerEvents = null;

      setTimeout(() => {
        document.body.classList.remove("nav-visible");
      }, this.duration / 16);
    }
  };

  show = () => {
    document.body.classList.add("nav-visible");

    this.animatedTextsInstances.forEach((instance, index) => {
      setTimeout(() => {
        if (instance.show) {
          instance.show();
        }
      }, this.duration / 2 + index * 24);
    });
  };

  changeState = (transition = true) => {
    if (!this.opened) {
      this.show();
    } else {
      this.hide(transition);
    }

    this.opened = !this.opened;
  };

  onHamburgerClick = () => {
    this.changeState();
  };

  updateActiveNavLink = () => {
    const route = window.location.pathname.replace(/\/$/, "");
    this.animatedTexts &&
      this.animatedTexts.forEach((node) => {
        node.classList.toggle(
          "text-reveal--revealed",
          node.parentNode.getAttribute("href").replace(/\/$/, "") ===
            route.replace(/\/$/, "")
        );
      });
  };

  onReady = () => {};

  onComplete = () => {
    this.toggleButtons = document.querySelectorAll(".hamburger");

    this.toggleButtons.forEach((button) => {
      button.addEventListener("click", this.onHamburgerClick);
    });

    if (this.animatedTexts) {
      this.animatedTexts.forEach((node) => {
        const instance = getInstance(node);

        if (instance) {
          this.animatedTextsInstances.push(instance);
        }
      });
    }

    this.updateActiveNavLink();
  };
}
