import "./style.css";
import layout from "./layout.html";

export default class Header {
  static selector = "header";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.animatedTexts = block.querySelectorAll(".header__menu .text-reveal");
    this.anchors = block.querySelectorAll(".header__menu__item a");
  }

  setHeaderHeight = () => {
    const { height } = this.block.getBoundingClientRect();
    document.documentElement.style.setProperty(
      "--header-height",
      `${height}px`
    );
  };

  updateActiveNavLink = () => {
    const route = window.location.pathname.replace(/\/$/, "");
    this.animatedTexts &&
      this.animatedTexts.forEach((node) => {
        node.classList.toggle(
          "text-reveal--revealed",
          node.parentNode.getAttribute("href").replace(/\/$/, "") ===
            route.replace(/\/$/, "")
        );
      });
  };

  onReady = () => {
    this.mounted = true;
    this.onResize();
  };

  onComplete = () => {
    this.updateActiveNavLink();
  };

  onPageChangeComplete = () => {};

  onResize = () => {
    this.setHeaderHeight();
  };
}
