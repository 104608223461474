import "./style.css";
import mod from "@utils/mod";
import { getInstance } from "@app";

export default class Intro {
  static selector = ".intro";

  constructor(block) {
    this.block = block;
    this.titlesNode = block.querySelector(".intro__content__titles");
    this.titleNodes = block.querySelectorAll(".intro__content__titles h1");
    this.titleIndex = 0;
    this.titles = [];
  }

  updateTitle = (showDelay) => {
    this.titles.forEach((title, index) => {
      if (index === mod(this.titleIndex - 1, this.titles.length)) {
        title.hideToTop();
      }

      if (index === this.titleIndex) {
        title.show(showDelay);
      }
    });
  };

  setupTitlesNodeSizes = () => {
    this.titleNodes.forEach((node) => {
      if (node.offsetHeight >= this.titlesNode.offsetHeight) {
        this.titlesNode.style.height = `${node.offsetHeight}px`;
      }
    });
  };

  onReady = () => {
    this.mounted = true;
  };

  onPageChangeComplete = () => {
    this.onComplete();
  };

  onComplete = () => {
    this.onResize();
    this.titleNodes.forEach((node) => {
      this.titles.push(getInstance(node));
    });

    this.updateTitle(0);

    this.inte = setInterval(() => {
      this.titleIndex = mod(this.titleIndex + 1, this.titleNodes.length);
      this.updateTitle(480);
    }, 5600);
  };

  onResize = () => {
    this.setupTitlesNodeSizes();
  };
}
