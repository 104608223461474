import "./style.css";
import layout from "./layout.html";

import { getRaf } from "@app";

export default class Loader {
  constructor() {
    this.block = document.querySelector(".loader");
    this.block.innerHTML = layout;
    this.bands = this.block.querySelectorAll(".loader__content__bands__band");

    this.transitionDuration = 1600;
    this.progress = 0;
    this.loadCallbacks = [];

    const raf = getRaf();
    raf.register("loader", this.hide);

    const resourcesToLoad = document.querySelectorAll("img[src]:not([src=''])");
    if (resourcesToLoad.length > 0) {
      this.resourcesAmount = resourcesToLoad.length;
      this.resourcesLoaded = 0;

      resourcesToLoad.forEach((r) => {
        let image = new Image();
        image.addEventListener("load", () => {
          this.resourcesLoaded += 1;

          this.progress = this.resourcesLoaded / this.resourcesAmount;
        });
        image.src = r.src;
      });
    } else {
      this.progress = 1;
    }
  }

  subscribe = (callback) => {
    this.loadCallbacks.push(callback);
  };

  animate = () => {
    this.bands.forEach((band) => {
      band.style.transform = `scaleX(${1 * this.progress})`;
    });
  };

  loaded = () => {
    this.bands[0].removeEventListener("transitionend", this.loaded);
    this.loadCallbacks.forEach((callback, i) => {
      callback();
      this.loadCallbacks.splice(i, 1);
    });
    document.body.classList.add("loaded");
    this.block.style.pointerEvents = "none";
  };

  hide = () => {
    this.animate();

    if (this.progress === 1) {
      const raf = getRaf();
      raf.unregister("loader");

      this.bands[0].addEventListener("transitionend", this.loaded);
    }
  };
}
