import "./style.css";
import layout from "./layout.html";

export default class PageTransitioner {
  static selector = ".page-transitioner";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.bandNodes = block.querySelectorAll(".page-transitioner__bands__band");

    this.pageContentTransitionDuration = 0;
    this.bandNodeTransitionDelay = 48;
    this.bandNodeTransitionDuration = 1200;

    this.showCallbacks = [];
    this.hideCallbacks = [];
    this.colors = ["red", "blue", "light-blue"];
    this.colorRandomIndex = 0;
  }

  subscribeOnShow = (callback) => {
    this.showCallbacks.push(callback);
  };

  subscribeOnHide = (callback) => {
    this.hideCallbacks.push(callback);
  };

  toggleBandNodesTransitionDelay = (isShowing) => {
    this.bandNodes.forEach((node, index) => {
      node.style.transitionDelay = isShowing
        ? `${
            (this.bandNodeTransitionDelay / 1000) *
            (this.bandNodes.length - index)
          }s`
        : null;
    });
  };

  generateRandom = (min, max, exclude) => {
    var num = Math.floor(Math.random() * (max - min + 1)) + min;
    return num === exclude ? this.generateRandom(min, max, exclude) : num;
  };

  updateBandsColor = () => {
    this.colorRandomIndex = this.generateRandom(
      0,
      this.colors.length,
      this.colorRandomIndex
    );
    const color = this.colors[this.colorRandomIndex];
    this.block.style.color = `var(--${color})`;
  };

  show = () => {
    this.updateBandsColor();
    this.toggleBandNodesTransitionDelay(true);
    this.block.addEventListener("transitionend", this.shown);

    const totalDuration =
      this.bandNodeTransitionDuration +
      this.bandNodeTransitionDelay * this.bandNodes.length;

    document.body.classList.add("page-loading");
    setTimeout(() => {
      this.block.classList.add("page-transitioner--show");
    }, this.pageContentTransitionDuration * 0.4);

    /*setTimeout(() => {
      this.shown();
    }, totalDuration * 0.6);*/
  };

  hide = () => {
    this.block.addEventListener("transitionend", this.hidden);
    this.block.classList.remove("page-transitioner--show");
  };

  shown = () => {
    this.block.removeEventListener("transitionend", this.shown);

    document.body.classList.remove("show");
    document.body.classList.remove("changing");
    this.showCallbacks.forEach((callback) => {
      callback();
    });
    this.showCallbacks = [];
  };

  hidden = () => {
    this.block.removeEventListener("transitionend", this.hidden);

    document.body.classList.remove("page-loading");
    this.hideCallbacks.forEach((callback) => {
      callback();
    });
    this.hideCallbacks = [];
  };

  onReady = () => {};
}
